<template>
    <div>
        <img :src="urlImg" style="width: 100%;height: auto">
        <div class="btn" @click="changeType">
            <img :src="fixUrl" style="width: 100px;height: auto">
        </div>

        <van-popup v-model="show">
            <div style="width: 90%;border-radius: 20px;margin: auto">
                <van-form @submit="onSubmit">
                    <van-field
                            v-model="name"
                            name="name"
                            label="您的姓名"
                            maxlength="10"
                            placeholder="请填写您的姓名"
                    />
                    <van-field
                            v-model="telephone"
                            name="telephone"
                            maxlength="11"

                            label="您的联系方式"
                            placeholder="请填写您的联系方式"
                    />
                    <van-field
                            name="address"
                            v-model="address"
                            rows="1"
                            autosize
                            maxlength="100"
                            type="textarea"
                            label="您的收货地址"
                            placeholder="请填写您的收货地址"
                    />
                    <div style="margin: 30px;">
                        <van-button round block type="info" native-type="submit"
                                    style="background-color: #c94253;border: 1px solid #c94253">预约报名
                        </van-button>
                    </div>
                </van-form>
            </div>
        </van-popup>
    </div>
</template>

<script>
    import {bagBuy} from "../api";

    export default {
        name: "sigUp",
        data() {
            return {
                telephone: '',
                name: '',
                address: '',
                show: false,
                urlImg: require('../assets/bag_yushou.jpg'),
                fixUrl: require('../assets/fix.png'),
            }

        },
        mounted() {
            // if (this.$route.params.token) {
            //     let str = this.$route.params.token
            //     if (str.endsWith('?')) {
            //         str = str.slice(0, str.length - 1)
            //     }
            //     sessionStorage.setItem("token", str)
            // }
        },
        methods: {
            changeType() {
                this.show = true;
            },
            onSubmit(values) {
                console.log('submit', values);
                if (values.name === '') {
                    this.$dialog.alert({
                        message: '请填写您的姓名',
                    }).then(() => {
                        // on close
                    });
                    return
                }
                if (values.name.length > 5) {
                    this.$dialog.alert({
                        message: '请填写正确姓名',
                    }).then(() => {
                        // on close
                    });
                    return
                }
                if (values.telephone === '') {
                    this.$dialog.alert({
                        message: '请填写您的联系方式',
                    }).then(() => {
                        // on close
                    });
                    return
                }
                if (values.telephone) {
                    var regex = /^1[0-9]{10}$/
                    if (!regex.test(this.telephone)) {
                        this.$dialog.alert({
                            message: '请填写正确的联系方式',
                        }).then(() => {
                            // on close
                        });
                        return
                    }
                }
                if (values.address === '') {
                    this.$dialog.alert({
                        message: '请填写您的收获地址',
                    }).then(() => {
                        // on close
                    });
                    return
                }
                bagBuy(values).then(() => {
                    this.$dialog.alert({
                        title: '预约报名成功',
                        message: '请您耐心等待客服回电！',
                    }).then(() => {
                        // on close
                        this.telephone = ''
                        this.name = ''
                        this.address = ''
                        this.show = false
                    });
                }).catch(err => {
                    this.$dialog.alert({
                        message: err,
                    }).then(() => {
                        // on close
                    });
                })
            },
        },
    }
</script>

<style scoped>
    .title {
        width: 60%;
        margin: 20px auto;
        background: linear-gradient(91deg, #d94d5e, #a32938);
        border-radius: 20px;
        text-align: center;
        padding: 8px 0;
        color: #ffffff;
        font-weight: bold;
    }

    .van-cell {
        display: block;
    }

    .btn {
        position: fixed;
        z-index: 1000;
        right: 20px;
        bottom: 100px;
        color: #333333;
        font-weight: bold;
    }

    /deep/ .van-popup {
        width: 89%;
        border-radius: 20px;
    }
</style>
