<template>
    <div class="home">
        <div class="home-ban">
            <van-cell-group>
                <van-cell title="姓名"  class="studentName">
                    <van-field v-model="studentName" placeholder="请输入姓名" input-align="right"/>
                </van-cell>
                <van-cell title="性别" is-link :value="sexName" @click="sexFn"/>
                <van-cell title="学校" is-link :value="schoolName" @click="schoolFn"/>
                <van-cell title="年级" is-link :value="gradeName" @click="gradeFn"/>
                <van-cell title="班级" is-link :value="className" @click="classesFn"/>
            </van-cell-group>
            <div class="subMit" @click="subMitFn">保存</div>
        </div>

        <van-popup
                v-model="showSex"
                round
                position="bottom"
                :style="{ height: '50%' }">
            <van-picker
                    title="标题"
                    confirm-button-text="确认"
                    cancel-button-text="取消"
                    :columns="sexList"
                    show-toolbar
                    @confirm="sexConfirm"
                    @cancel="sexCancel"

            />
        </van-popup>

        <van-popup
                v-model="showGrade"
                round
                position="bottom"
                :style="{ height: '50%' }">
            <van-picker
                    title="标题"
                    confirm-button-text="确认"
                    cancel-button-text="取消"
                    :columns="gradeList"
                    show-toolbar
                    @confirm="schoolConfirm"
                    @cancel="schoolCancel"
                    :min-date="minDate"

            />
        </van-popup>
        <van-popup
                v-model="showClass"
                round
                position="bottom"
                :style="{ height: '50%' }">
            <van-picker
                    title="标题"
                    confirm-button-text="确认"
                    cancel-button-text="取消"
                    :columns="classList"
                    show-toolbar
                    @confirm="classConfirm"
                    @cancel="classCancel"

            />
        </van-popup>
    </div>
</template>

<script>
    import wx from 'weixin-js-sdk'
    import {mealsGradeSelect,mealsClassSelect,mealsStudentUpdate,mealsStudentInfo} from '../api/index'
    import {Notify} from "vant";
    export default {
        name: "studentList",
        data() {
            const formatter = (type, val) => {
                if (type === 'year') {
                    return `${val}年`;
                }
                if (type === 'month') {
                    return `${val}月`;
                }
                if (type === 'day') {
                    return `${val}日`;
                }
                return val;
            };
            return {
                studentName: '',
                sexName:'未填写',
                birthday:'未填写',
                className:'未填写',
                schoolName:"未填写",
                gradeName:'未填写',
                gradeId:'',
                classId:'',
                studentId:'',
                sexList :['男', '女'],
                schoolList:[],
                gradeList:[],
                classList:[],
                schoolId:"",
                currentDate : new Date(),
                formatter,
                showSex:false,
                showDate:false,
                showGrade:false,
                showClass:false,
                minDate:new Date(1999,0,1)

            };
        },
        activated() {
            if(sessionStorage.getItem("mealsSchoolName")){
                this.schoolName = sessionStorage.getItem("mealsSchoolName")
            }

            this.schoolId = sessionStorage.getItem("mealsSchoolId")
        },
        created() {
            if(this.$route.params.token){
                let str = this.$route.params.token
                if(str.endsWith('?')){
                    str = str.slice(0,str.length-1)
                }
                sessionStorage.setItem("meals-token",str)
            }
            if(this.$route.params.id){
                this.studentId = this.$route.params.id
                this.mealsStudentInfo()
            }
        },
        methods: {
            //获取学生详情
            mealsStudentInfo(){
                mealsStudentInfo(this.studentId).then(res=>{
                    let data = res.data
                    this.studentName = data.studentName
                    this.schoolId=data.schoolId
                    if(data.sex==1){
                        this.sexName = '男'
                    }else{
                        this.sexName = '女'
                    }
                    this.schoolName = data.schoolName
                    this.birthday = data.birthday
                    this.className = data.className
                    this.classId = data.classId
                    this.gradeName = data.gradeName
                    this.gradeId = data.gradeId
                    this.mealsClassSelect()
                })
            },
            //获取年级列表
            mealsGradeSelect(){
                let id = this.schoolId
                mealsGradeSelect(id).then(res=>{
                    let arr = res.data
                    let arry = []
                    for(let i in arr){
                        let obj = {
                            text:arr[i].gradeName,
                            id:arr[i].gradeId
                        }
                        arry.push(obj)
                    }
                    this.gradeList = arry
                    this.showGrade = true
                })
            },
            //获取班级列表
            mealsClassSelect(){
                let id = this.schoolId
                mealsClassSelect(id,this.gradeId).then(res=>{
                    console.log(res)
                    let arr = res.data
                    let arry = []
                    for(let i in arr){
                        let obj = {
                            text:arr[i].className,
                            id:arr[i].classId
                        }
                        arry.push(obj)
                    }
                    this.classList = arry
                })
            },
            //打开性别选择
            sexFn(){
                this.showSex = true
            },
            //性别确认
            sexConfirm(value, index) {
                console.log((`当前值: ${value}, 当前索引: ${index}`))
                this.sexName = value
                this.showSex = false
            },
            //性别取消
            sexCancel(){
                this.showSex = false
            },
            //打开班级
            classesFn(){
                if(this.gradeId===""){
                    Notify({type: 'warning', message: '请先选择年级'})
                    return
                }
                if(this.classList.length==0){
                    Notify({type: 'warning', message: '该年级下无班级'})
                    return
                }
                this.showClass = true
            },
            //  班级确认
            classConfirm(value) {
                this.className = value.text
                this.classId = value.id
                this.mealsClassSelect()
                this.showClass = false
            },
            //班级取消
            classCancel(){
                this.showClass = false
            },
            //打开学校
            schoolFn(){
                Notify({type: 'warning', message: '学校不能更改'})
                // this.$router.push("/schoolList")
            },
            //打开年级
            gradeFn(){
                if(this.schoolId===""||this.schoolId===undefined||this.schoolId===null){
                    Notify({type: 'warning', message: '请先选择学校'})
                    return
                }
                this.mealsGradeSelect()

            },
            //  年级确认
            schoolConfirm(value) {
                this.gradeName = value.text
                this.gradeId = value.id
                this.classList = []
                this.className = '未填写'
                this.classId = ''
                this.mealsClassSelect()
                this.showGrade = false
            },
            //年级取消
            schoolCancel(){
                this.showGrade = false
            },
            //打开日期
            dateFn(){
                this.showDate = true
            },
            //  日期确认
            dateConfirm(value) {
                let time = this.dateFormat(value)
                this.birthday = time
                this.showDate = false
            },
            //日期取消
            dateCancel(){
                this.showDate = false
            },
            //时间格式化函数，此处仅针对yyyy-MM-dd hh:mm:ss 的格式进行格式化
            dateFormat(time) {
                let date=new Date(time);
                let year=date.getFullYear();
                /* 在日期格式中，月份是从0开始的，因此要加0
                 * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
                 * */
                let month= date.getMonth()+1<10 ? "0"+(date.getMonth()+1) : date.getMonth()+1;
                let day=date.getDate()<10 ? "0"+date.getDate() : date.getDate();
                // 拼接
                // return year+"-"+month+"-"+day+" "+hours+":"+minutes+":"+seconds;
                return year+"-"+month+"-"+day
            },
            subMitFn(){
                if(this.studentName===""){
                    Notify({type: 'warning', message: '请填写姓名'})
                    return
                }
                let pattern = /^[\u4e00-\u9fa5]{0,}$/
                if (!pattern.test(this.studentName)) {
                    Notify({type: 'warning', message: '请填写正确姓名'})
                    return
                }
                if(this.sexName==="未填写"){
                    Notify({type: 'warning', message: '请选择性别'})
                    return
                }
                if(this.birthday==="未填写"){
                    Notify({type: 'warning', message: '请选择出生年月'})
                    return
                }
                if(this.schoolId===""){
                    Notify({type: 'warning', message: '请选择学校'})
                    return
                }
                if(this.gradeId===""){
                    Notify({type: 'warning', message: '请选择年级'})
                    return
                }
                if(this.classId===""){
                    Notify({type: 'warning', message: '请选择班级'})
                    return
                }
                let sex = ""
                if(this.sexName==='男'){
                    sex = 1
                }else{
                    sex = 2
                }
                let info = {
                    studentId:this.studentId,
                    studentName:this.studentName,
                    schoolId:this.schoolId,
                    gradeId:this.gradeId,
                    classId:this.classId,
                    sex:sex,
                }
                mealsStudentUpdate(info).then(res=>{
                    if(res.code===0){
                        Notify({ type: 'success', message: '修改成功' });
                        // wx.miniProgram.redirectTo({ url: '/pages/studentinfo/list/list' })
                        // wx.miniProgram.navigateTo({ url: '/pages2/pages/homeSchoolCall/students/students' })
                        // wx.miniProgram.switchTab({url: "/pages/index/home/home"})
                        wx.miniProgram.navigateBack({url: '/pages/studentInfo/list/list'})
                    }
                })
                console.log(info)
            },
        }
    };
</script>
<style scoped>
    .home {

        height: 100vh;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        color: #fff;
    }
    .home-ban {
        width: 100%;
        height: 100%;
        background-color: #ffffff;
    }
    ::v-deep .van-search {
        padding: 0.1rem 0.32rem;
    }
    .studentName .van-cell{
        padding: 0;
    }
    .subMit{
        width: 90%;
        height: 40px;
        line-height: 40px;
        background: #009EEF;
        color: #fff;
        font-size: 18px;
        margin: 0 auto;
        text-align: center;
        border-radius: 10px;
        margin-top: 30px;
    }
</style>
