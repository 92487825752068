import Router from 'vue-router'
import Vue from 'vue'
import Carousel from '../components/Carousel'
import GoodsDetail from "../components/GoodsDetail"
import Download from "../components/Download"
import Doc from "../components/Doc"
import PlanSchool from "../components/PlanSchool";
import Growth from '../components/Growth'
import Heart from '../components/Heart'
import Activity from '../components/Activity'
import ExperienceCourse from '../components/ExperienceCourse'
import GrowthDiary from "../components/GrowthDiary";
import HealthInstitution from "../components/HealthInstitution";
import Join from '../components/Join'
import openBrowser from "../components/openBrowser";
import Video from '../components/video'
import studentList from '../components/StudentList'
import schoolList from '../components/SchoolList'
import UpDateStudent from '../components/UpDateStudent'
import sigUp from '../components/sigUp'
import ceshi from '../components/ceshi'
import mealsStudentList from '../components/MealsStudent'
import mealsSchoolList from '../components/MealsSchoolList'
import MealsUpDateStudent from '../components/MealsUpDateStudent'
import dinnerStudentList from '../components/DinnerStudent'
import dinnerSchoolList from '../components/DinnerSchoolList'
import dinnerUpDateStudent from '../components/DinnerUpDateStudent.vue'

Vue.use(Router)

const routes = [
    {
        path: '/ceshi/:token', component: ceshi, name: 'ceshi'
    },
    {
        path: '/register/:token', component: sigUp, name: 'sigUp'
    },
    {
        path: '/studentList/:token', component: studentList, name: 'studentList'
    },
    // meals
    {
        path: '/mealsStudentList/:token', component: mealsStudentList, name: 'mealsStudentList',
        meta: { title: '学生列表' }
    },
    {
        path: '/UpDateStudent/:token/:id', component: UpDateStudent, name: 'UpDateStudent'
    },
    {
        path: '/mealsUpDateStudent/:token/:id', component: MealsUpDateStudent, name: 'MealsUpDateStudent',
        meta: { title: '修改学生' }
    },
    {
        path: '/mealsSchoolList', component: mealsSchoolList, name: 'mealsSchoolList',
        meta: { title: '学校列表' }
    },
    // dinner
    {
        path: '/dinnerStudentList/:token', component: dinnerStudentList, name: 'dinnerStudentList',
        meta: { title: '学生列表' }
    },
    {
        path: '/dinnerSchoolList', component: dinnerSchoolList, name: 'dinnerSchoolList',
        meta: { title: '学校列表' }
    },
    {
        path: '/dinnerUpDateStudent/:token/:id', component: dinnerUpDateStudent, name: 'dinnerUpDateStudent',
        meta: { title: '修改学生' }
    },
    {
        path: '/schoolList', component: schoolList, name: 'schoolList'
    },
    {
        path: '/carousel', component: Carousel, name: 'carousel'
    },
    {
        path: '/goods', component: GoodsDetail, name: 'goods'
    },
    {
        path: '/download', component: Download, name: 'download'
    },
    {
        path: '/doc', component: Doc, name: 'doc'
    },
    {
        path: '/planSchool', component: PlanSchool, name: 'planSchool'
    },
    {
        path: '/growth', component: Growth, name: 'growth'
    },
    {
        path: '/heart', component: Heart, name: 'heart'
    },
    {
        path: '/activity', component: Activity, name: 'activity'
    },
    {
        path: '/experienceCourse', component: ExperienceCourse, name: 'experienceCourse'
    },
    {
        path: '/growthDiary', component: GrowthDiary, name: 'growthDiary'
    },
    {
        path: '/healthInstitution', component: HealthInstitution, name: 'healthInstitution'
    },
    {
        path: '/openBrowser', component: openBrowser, name: 'openBrowser'
    },
    {
        path: '/join', component: Join, name: 'Join'
    },
    {
        path: '/video', component: Video, name: 'Video'
    }
]

const router = new Router({
    mode: 'hash',
    scrollBehavior: () => ({y: 0}),
    routes: routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title || '' // 获取路由元信息中的标题或者默认空值作为网页标题
    next()
})


export default router
