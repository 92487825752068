<template>
    <div class="box">
        <van-row>
            <van-col span="24">
                <video :src="mediaUrl" poster="../assets/fabu.png" controls="controls"
                       webkit-playsinline="true"
                       playsinline="true"
                       x5-video-player-type='h5'
                       x5-video-player-fullscreen='true'
                       x5-video-player-orientation="landscape"
                       width="100%" height="100%" >
                </video>
            </van-col>
        </van-row>
    </div>
</template>

<script>
  export default {
    name: "vi",
    data() {
      return {
        mediaUrl: require('../assets/propaganda.mp4')
      }
    }
  }

</script>

<style scoped>
    .box {
        width: 100%;
        height: 100vh;
        background-image: url("../assets/bag1.jpg");
        /*background-color: #bedfff;*/
    }
</style>
