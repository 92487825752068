<template>
    <div class="home">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
            <van-swipe-item v-for="(item,index) in list.gallery" :key="index">
                <img :src="item" style="width: 100%" height="200px">
            </van-swipe-item>
        </van-swipe>
        <div class="content">
            <p class="title">{{list.name}}</p>
            <div style="display: flex">
                <p class="tags" v-for="(item,index) in list.tags" :key="index">{{item}}</p>
            </div>
            <div class="location"><van-icon name="map-marked" /><span>{{list.location}}</span></div>
            <div style="margin-top: 10px" class="detail">
                <h3 >中心简介</h3>
                <div v-html="list.detail"></div>
            </div>
        </div>
    </div>
</template>

<script>
  import {healthInstitution} from "../api";

  export default {
    name: "HealthInstitution",
    data() {
      return {
        // 1414188436851692802
        healthInstitutionId: '',
        list: {}
      }
    },
    methods: {
      getData() {
        healthInstitution(this.healthInstitutionId).then(resp => {
          console.log(resp)
          this.list = resp.data
        })
      }

    },
    mounted() {
      this.healthInstitutionId = this.$route.query.healthInstitutionId
      this.getData()
    }
  }
</script>

<style scoped>
    .home {
        width: 100%;
        height: 100vh;
        background-color: #fff;
    }

    .content {
        padding-left: 20px;
    }

    .content .title {
        font-size: 20px;
        margin-top: 5px;
        font-weight: bold;
    }
    .location{
        margin-top: 15px;
        font-size: 16px;
        color: #626262 ;
    }
    .location /deep/.van-icon {
        color: #0581f8;
    }
    .tags{
        background: #E8F5FF;
        border-radius: 24px;
        padding: 5px 20px;
        font-weight: 500;
        color: #0A6AEB;
        margin-right: 5px;
        margin-top: 10px;

    }
    .detail h3{
        margin-top: 20px;
        margin-bottom: 10px;
    }
</style>
