<template>
    <div class="download" style="width: 100% !important;">
        <div style=" width: 100%;
        height: 100vh;">
            <p class="title">学子笈</p>
            <p class="subtitle">学校更省心·家长更放心·学生更悦心</p>
            <div class="imgBox">
                <img src="../assets/home.png">
            </div>
            <div class="btnBox">
            </div>
            <div class="btn" @click="downloadAndroid"><img src="../assets/android.png"
                                                           style="vertical-align: middle;margin-right: 5px">Android下载
            </div>
            <div class="btn" @click="downloadIos" style="margin-top: 20px;bottom: 35px;color: #1296db"><img
                    src="../assets/ios.png"
                    style="vertical-align: middle;margin-right: 5px;">IOS下载
            </div>
        </div>
        <div class="browser" id="WXShadow"></div>
        <div class="imgBag"> <img :src="url" ></div>
    </div>
</template>

<script>
  // //移动端的浏览器版本都比较高，可以监听DOMContentLoaded，而不是load，load会相对慢一点点，具体按事件使用情况而定。
  // document.addEventListener("DOMContentLoaded", function() {
  //   var USER_Agent = navigator.userAgent;
  //   var MOBILE_IOS = /(iPhone|iPad|iPod|iOS)/i;
  //   var MOBILE_Android = /(Android)/i;
  //   var WX = /(micromessenger)/i;
  //   var userAgentInfo = navigator.userAgent;
  //   if (WX.test(USER_Agent)) {
  //     //如果在微信中打开则只显示一个提示用户的罩层
  //     return;
  //   } else if (MOBILE_IOS.test(USER_Agent)) {
  //     document.getElementById("WXShadow").style.display = "none";
  //     window.location.href = "https://scios.xueziji.com/app/" //苹果app跳转的协议
  //   } else if (MOBILE_Android.test(USER_Agent)) {
  //     // document.getElementById("WXShadow").style.display = "none";
  //     // window.location.href = "wangyy://com.yongmai.enclosure/web" //安卓app跳转的协议
  //   }
  //   //如果没有唤起app则3秒后会跳转到下载地址，反之则不会跳转到下载地址。
  //   if (MOBILE_Android.test(USER_Agent)) {
  //     setTimeout(function() {
  //       if(userAgentInfo.indexOf("HUAWEI") !== -1){
  //         window.location.href = "https://appgallery.huawei.com/app/C104985961?sharePrepath=ag&locale=zh_CN&source=appshare&subsource=C104985961&shareTo=weixin&shareFrom=appmarket"; //下载地址
  //       }else if(userAgentInfo.indexOf("vivo") !== -1) {
  //         window.location.href = "https://h5coml.vivo.com.cn/h5coml/appdetail_h5/browser_v2/index.html?appId=3347877&resource=301&source=2"
  //       }else{
  //         window.location.href = "https://scapi.xueziji.com/sc-app/app/sc.apk"; //下载地址
  //       }
  //     }, 1000);
  //   }
  //   // if (MOBILE_IOS.test(USER_Agent)) {
  //   //  setTimeout(function() {
  //   //   window.location.href = "https://apps.apple.com/cn/app/id1592806899"; //下载地址
  //   //  }, 3000);
  //   // }
  // });
  export default {
    name: "Download",
    data() {
      return {
        androidUrl: process.env.VUE_APP_AndroidURL,
        iosUrl: process.env.VUE_APP_iosURL,
        url:require('../assets/bag.png')
      }
    },
    activated() {
      var USER_Agent = navigator.userAgent;
      var MOBILE_IOS = /(iPhone|iPad|iPod|iOS)/i;
      var MOBILE_Android = /(Android)/i;
      var WX = /(micromessenger)/i;
      // var userAgentInfo = navigator.userAgent;
      if (WX.test(USER_Agent)) {
        //如果在微信中打开则只显示一个提示用户的罩层
        return;
      } else if (MOBILE_IOS.test(USER_Agent)) {
        document.getElementById("WXShadow").style.display = "none";
        window.location.href = "https://apps.apple.com/cn/app/id1592806899" //苹果app跳转的协议
      } else if (MOBILE_Android.test(USER_Agent)) {
        // document.getElementById("WXShadow").style.display = "none";
        // window.location.href = "wangyy://com.yongmai.enclosure/web" //安卓app跳转的协议
      }
      //如果没有唤起app则3秒后会跳转到下载地址，反之则不会跳转到下载地址。
      if (MOBILE_Android.test(USER_Agent)) {
        setTimeout(function() {
          window.location.href = "http://scopenoss.xueziji.com/android/sc.apk";
          // if(userAgentInfo.indexOf("HUAWEI") !== -1){
          //
          //   window.location.href = "https://appgallery.huawei.com/app/C104985961?sharePrepath=ag&locale=zh_CN&source=appshare&subsource=C104985961&shareTo=weixin&shareFrom=appmarket"; //下载地址
          // }else if(userAgentInfo.indexOf("vivo") !== -1) {
          //   window.location.href = "https://h5coml.vivo.com.cn/h5coml/appdetail_h5/browser_v2/index.html?appId=3347877&resource=301&source=2"
          // }else{
          //   window.location.href = "http://scopenoss.xueziji.com/android/sc.apk"; //下载地址
          // }
        }, 1000);
      }
      // if (MOBILE_IOS.test(USER_Agent)) {
      //  setTimeout(function() {
      //   window.location.href = "https://apps.apple.com/cn/app/id1592806899"; //下载地址
      //  }, 3000);
      // }
    },
    methods: {
      downloadAndroid() {
        window.location.href = this.androidUrl
      },
      downloadIos() {
        window.location.href = this.iosUrl
      }
    }
  }


</script>

<style scoped>
    .download {
        width: 100%;
        height: 100vh;
        background-color: #0295e3;
        color: #ffffff;
        text-align: center;
        overflow: hidden;
        position: relative;
    }

    .title {
        font-size: 48px;
        margin-top: 8%;
        font-family: cursive;
        font-weight: 600;
    }

    .subtitle {
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 33px;
        font-size: 33px;
        font-family: monospace;
        line-height: 41px;
    }

    .imgBox img {
        width: 50%;
        margin-top: 20px;
    }

    .btnBox {
        position: fixed;
        bottom: -20px;
        height: 200px;
        width: 100%;
        z-index: 2;
        transform: skew(0deg, -6deg);
        background: linear-gradient(to bottom, #3cb9fb, #0295e3);
    }

    .btn {
        height: 50px;
        width: 80%;
        border-radius: 10px;
        background-color: white;
        z-index: 3;
        position: fixed;
        bottom: 100px;
        margin-left: 10%;
        line-height: 50px;
        color: #2fc2b0;
    }

    .browser {
        width: 100% !important;
        height: 100vh !important;
        background-color: black !important;
        opacity: 0.8;
        position: absolute !important;
        z-index: 180;
        top: 0;
        left: 0;
    }
    .imgBag{
        width: 100%;
        height: 100vh;
        position: absolute;
        top: 0;
        z-index: 220;
    }
    .imgBag img{
        width: 80%;
    }
</style>
