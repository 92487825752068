import request from '@/utils/request'
import qs from "qs"

export function carousel(carouselId) {
    const params = {carouselId}
    return request({
        method: 'get',
        url: '/app/link/carousel',
        params: params
    })
}

export function goods(goodsId) {
    const params = {goodsId}
    return request({
        method: 'get',
        url: '/app/link/goods',
        params: params
    })
}

export function doc(docId) {
    const params = {docId}
    return request({
        method: 'get',
        url: '/app/link/doc',
        params: params
    })
}

export function planSchool(planSchoolId) {
    const params = {planSchoolId}
    return request({
        method: 'get',
        url: '/app/link/planSchool',
        params: params
    })
}

// growth/diary/info
export function growthInfo(id) {
    const params = {id}
    return request({
        method: 'get',
        url: '/growth/diary/info',
        params: params
    })
}

export function wholeInfo(id) {
    const params = {id}
    return request({
        method: 'get',
        url: '/whole/heart/info',
        params: params
    })
}

export function activity(activityId) {
    const params = {activityId}
    return request({
        method: 'get',
        url: '/app/link/activity',
        params: params
    })
}

// experienceCourse
// /sc-app/app/link/experienceCourse
export function experienceCourse(experienceCourseId) {
    const params = {experienceCourseId}
    return request({
        method: 'get',
        url: '/app/link/experienceCourse',
        params: params
    })
}

// growthDiary?growthDiaryId=1409850949379448320
// /sc-app/app/link/growthDiary
export function growthDiary(growthDiaryId) {
    const params = {growthDiaryId}
    return request({
        method: 'get',
        url: '/app/link/growthDiary',
        params: params
    })
}

export function healthInstitution(healthInstitutionId) {
    const params = {healthInstitutionId}
    return request({
        method: 'get',
        url: '/app/link/healthInstitution',
        params: params
    })
}

export function join(contacts, contactWay) {
    const data = {contacts, contactWay}
    return request({
        method: 'POST',
        url: '/app/attract',
        data: qs.stringify(data)
    })
}

//年级下拉框
export function gradeSelect(schoolId) {
    const data = {schoolId}
    return request({
        method: 'POST',
        url: '/child/gradeSelect',

        headers: {'auth-token': sessionStorage.getItem("token")},
        data: qs.stringify(data)
    })
}

//班级下拉框
export function classSelect(schoolId, gradeId) {
    const data = {schoolId, gradeId}
    return request({
        method: 'POST',
        url: '/child/classSelect',

        headers: {'auth-token': sessionStorage.getItem("token")},
        data: qs.stringify(data)
    })
}

//学校列表
export function schoolList(schoolName, longitude, latitude, current, size) {
    const data = {schoolName, longitude, latitude, current, size}
    return request({
        method: 'POST',
        url: '/child/schoolList',

        headers: {'auth-token': sessionStorage.getItem("token")},
        data: qs.stringify(data)
    })
}

//添加学生
export function childSave({studentName, schoolId, gradeId, classId, className, birthday, sex}) {
    const data = {studentName, schoolId, gradeId, classId, className, birthday, sex}
    return request({
        method: 'POST',
        url: '/child/save',
        headers: {'auth-token': sessionStorage.getItem("token")},
        data: qs.stringify(data)
    })
}

//修改学生
export function childUpdate({studentId, studentName, schoolId, gradeId, classId, className, birthday, sex}) {
    const data = {studentId, studentName, schoolId, gradeId, classId, className, birthday, sex}
    return request({
        method: 'POST',
        url: '/child/update',

        headers: {'auth-token': sessionStorage.getItem("token")},
        data: qs.stringify(data)
    })
}

//学生详情
export function childInfo(childId) {
    const data = {childId}
    return request({
        method: 'POST',
        url: '/child/info',

        headers: {'auth-token': sessionStorage.getItem("token")},
        data: qs.stringify(data)
    })
}


/**
 *@name:学生餐添加学生信息
 *@description:
 *@author:yyy1022
 *@time:2024/1/20/020
 **/
export function mealsStudentSave({studentName, schoolId, gradeId, classId, sex}) {
    const data = {studentName, schoolId, gradeId, classId, sex}
    return request({
        method: 'POST',
        url: '/meals/student/save',
        headers: {'auth-token': sessionStorage.getItem("meals-token")},
        data: qs.stringify(data)
    })
}

/**
 *@name://学生餐年级下拉框
 *@description:
 *@author:yyy1022
 *@time:2024/1/20/020
 **/

export function mealsGradeSelect(schoolId) {
    const data = {schoolId}
    return request({
        method: 'POST',
        url: '/meals/student/gradeSelect',
        headers: {'auth-token': sessionStorage.getItem("meals-token")},
        data: qs.stringify(data)
    })
}

/**
 *@name:学生餐班级下拉框
 *@description:
 *@author:yyy1022
 *@time:2024/1/20/020
 **/
export function mealsClassSelect(schoolId, gradeId) {
    const data = {schoolId, gradeId}
    return request({
        method: 'POST',
        url: '/meals/student/classSelect',
        headers: {'auth-token': sessionStorage.getItem("meals-token")},
        data: qs.stringify(data)
    })
}

/**
 *@name:学生餐学校列表
 *@description:
 *@author:yyy1022
 *@time:2024/1/20/020
 **/
//学校列表
export function mealsSchoolList(schoolName, current, size) {
    const data = {schoolName, current, size}
    return request({
        method: 'POST',
        url: 'meals/student/schoolList',

        headers: {'auth-token': sessionStorage.getItem("meals-token")},
        data: qs.stringify(data)
    })
}

/**
 *@name:学生餐修改学生
 *@description:
 *@author:yyy1022
 *@time:2024/1/20/020
 **/
//
export function mealsStudentUpdate({studentId, studentName, schoolId, gradeId, classId, sex}) {
    const data = {studentId, studentName, schoolId, gradeId, classId, sex}
    return request({
        method: 'POST',
        url: '/meals/student/update',

        headers: {'auth-token': sessionStorage.getItem("meals-token")},
        data: qs.stringify(data)
    })
}

/**
 *@name:学生餐学生详情
 *@description:
 *@author:yyy1022
 *@time:2024/1/20/020
 **/
//学生详情
export function mealsStudentInfo(studentId) {
    const data = {studentId}
    return request({
        method: 'POST',
        url: '/meals/student/info',
        headers: {'auth-token': sessionStorage.getItem("meals-token")},
        data: qs.stringify(data)
    })
}

/**
 *@name:书包申请购买
 *@description:
 *@author:yyy1022
 *@time:2023/6/20/020
 **/
export function bagBuy({name, telephone, address}) {
    const data = {name, telephone, address}
    return request({
        method: 'POST',
        url: '/school/bag/apply/record/save',
        headers: {'auth-token': sessionStorage.getItem("token")},
        data: qs.stringify(data)
    })
}