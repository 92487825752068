import Vue from 'vue'
import { Button } from "vant";
import { Col, Row } from 'vant';
import { Field } from 'vant';
import { CellGroup} from "vant";
import { ActionSheet } from 'vant';
import {Cell} from "vant";
import { Image as VanImage } from 'vant';
import { NavBar } from 'vant';
import { Stepper } from 'vant'
import { Tab, Tabs } from 'vant';
import { Empty } from 'vant';
import { List } from 'vant';
import { PullRefresh } from 'vant';
import { ContactCard } from 'vant';
import { Card } from 'vant';
import { Popup } from 'vant';
import { Tag } from 'vant';
import { Swipe, SwipeItem } from 'vant';
import { Lazyload } from 'vant';
import { GoodsAction, GoodsActionIcon, GoodsActionButton } from 'vant';
import { Icon } from 'vant';
import { Form } from 'vant';
import { Notify } from 'vant';
import { Picker } from 'vant';
import { DatetimePicker } from 'vant';
import { Search } from 'vant';
Vue.use(Notify);
import { Dialog } from 'vant';
Vue.use(Dialog);
Vue.use(Search);
Vue.use(DatetimePicker);
Vue.use(Picker);
Vue.use(Form);
Vue.use(Field);
Vue.use(Icon);
Vue.use(GoodsAction);
Vue.use(GoodsActionButton);
Vue.use(GoodsActionIcon);
Vue.use(Lazyload);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Tag);
Vue.use(Card);
Vue.use(Tag);
Vue.use(Popup);
Vue.use(PullRefresh);
Vue.use(List);
Vue.use(Empty);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Stepper);
Vue.use(NavBar);
Vue.use(VanImage);
Vue.use(Cell);
Vue.use(ActionSheet);
Vue.use(CellGroup);
Vue.use(Field);
Vue.use(Col);
Vue.use(Row);
Vue.use(Button);
Vue.use(ContactCard);

