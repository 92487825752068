import request from '@/utils/request'
import qs from "qs"

/**
 *@name://学生餐年级下拉框
 *@description:
 *@author:yyy1022
 *@time:2024/1/20/020
 **/

 export function dinnerGradeSelect(schoolId) {
  const data = {schoolId}
  return request({
      method: 'POST',
      url: '/dinner/student/gradeSelect',
      headers: {'auth-token': sessionStorage.getItem("dinner-token")},
      data: qs.stringify(data)
  })
}

/**
 *@name:学生餐班级下拉框
 *@description:
 *@author:yyy1022
 *@time:2024/1/20/020
 **/
 export function dinnerClassSelect(schoolId, gradeId) {
  const data = {schoolId, gradeId}
  return request({
      method: 'POST',
      url: '/dinner/student/classSelect',
      headers: {'auth-token': sessionStorage.getItem("dinner-token")},
      data: qs.stringify(data)
  })
}

/**
 *@name:学生餐添加学生信息
 *@description:
 *@author:yyy1022
 *@time:2024/1/20/020
 **/
 export function dinnerStudentSave({studentName, schoolId, gradeId, classId, sex}) {
  const data = {studentName, schoolId, gradeId, classId, sex}
  return request({
      method: 'POST',
      url: '/dinner/student/save',
      headers: {'auth-token': sessionStorage.getItem("dinner-token")},
      data: qs.stringify(data)
  })
}

/**
 *@name:学生餐学校列表
 *@description:
 *@author:yyy1022
 *@time:2024/1/20/020
 **/
//学校列表
export function dinnerSchoolList(schoolName, current, size) {
  const data = {schoolName, current, size}
  return request({
      method: 'POST',
      url: 'dinner/student/schoolList',

      headers: {'auth-token': sessionStorage.getItem("dinner-token")},
      data: qs.stringify(data)
  })
}

/**
 *@name:学生餐修改学生
 *@description:
 *@author:yyy1022
 *@time:2024/1/20/020
 **/
//
export function dinnerStudentUpdate({studentId, studentName, schoolId, gradeId, classId, sex}) {
  const data = {studentId, studentName, schoolId, gradeId, classId, sex}
  return request({
      method: 'POST',
      url: '/dinner/student/update',

      headers: {'auth-token': sessionStorage.getItem("dinner-token")},
      data: qs.stringify(data)
  })
}

/**
 *@name:学生餐学生详情
 *@description:
 *@author:yyy1022
 *@time:2024/1/20/020
 **/
//学生详情
export function dinnerStudentInfo(studentId) {
  const data = {studentId}
  return request({
      method: 'POST',
      url: '/dinner/student/info',
      headers: {'auth-token': sessionStorage.getItem("dinner-token")},
      data: qs.stringify(data)
  })
}