<template>
    <div class="home">
        <van-form action="">
            <van-field
                    v-model="valueSearch"
                    type="search"
                    autocomplete="off"
                    right-icon="search"
                    @click-right-icon="onSearch"  @keydown="onSearch">
            </van-field>
        </van-form>

        <!--        <van-search   type="search"  v-model="valueSearch" placeholder="请输入搜索关键词" @search="onSearch"/>-->
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    @load="onLoad"
                    offset="50"
            >
                <div v-for="item in list" :key="item.schoolId" class="navLi" @click="changeSchool(item.schoolId,item.name)">
                    <div class="right">
                        <p class="title">{{item.name}}</p>
                    </div>
                </div>
            </van-list>
        </van-pull-refresh>
    </div>
</template>

<script>
    import {dinnerSchoolList} from "../api/dinner";

    export default {
        name: "SchoolList",
        data() {
            return {
                valueSearch:'',
                list: [],
                loading: false,
                finished: false,
                refreshing: false,
                current:0,
                size:20,
                schoolName:''
            };
        },
        created() {
            // this.schoolList()
        },
        mounted(){
        },
        methods: {
            //列表
            schoolList(){
                dinnerSchoolList(this.schoolName,this.current,this.size).then(res=>{
                    let arr = this.list
                    this.list= [...arr, ...res.data.list]
                    if (res.data.list.length === 0) {
                        this.finished = true
                        this.$toast('暂无更多了...')
                    }
                })
            },
            onLoad() {
                // setTimeout(() => {
                if (this.refreshing) {
                    this.list = [];
                    this.refreshing = false;
                }
                this.current = this.current+1
                this.schoolList()
                this.loading = false;

                if (this.finished === true)  {
                    this.finished = true;
                }
                // }, 1000);
            },
            onRefresh() {
                // 清空列表数据
                this.current = 0
                this.finished = false;

                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = true;
                this.onLoad();
            },
            //搜索
            onSearch() {
                console.log(this.valueSearch,5555)
                this.schoolName = this.valueSearch
                this.current = 1
                this.list = []
                this.schoolList()
            },
            //    选择学校
            changeSchool(e,m){
                sessionStorage.setItem("dinnerSchoolName",m)
                sessionStorage.setItem("dinnerSchoolId",e)
                const a = sessionStorage.getItem("dinner-token")
                this.$router.push(`/dinnerStudentList/${a}`)
            }
        },
    }
</script>

<style scoped>
    .home{
        /*overflow: hidden;*/
        padding: 10px;
        box-sizing: border-box;
        height: 100vh;
        width: 100%;
    }
    .van-pull-refresh{
        height: calc(100vh - 50px) !important;
        overflow: auto !important;
    }
    .van-list{
        height: auto !important;
    }
    .navLi{
        display: flex;
        background: #ffffff;
        margin-top: 10px;
        padding: 10px;
        box-sizing: border-box;
        border-radius: 5px;
    }
    .Img{
        width: 58px;
        height: 58px;
    }
    .right{
        flex: 1;
        padding-left: 15px;
        box-sizing: border-box;
    }
    .title{
        font-size: 14px;
        color: #333;
    }
    .guiGe{
        overflow: hidden;
    }
    .guiGe span{
        color: #80B5FF;
        font-size: 10px;
        padding: 1px 5px;
        border-radius: 3px;

        margin-right: 5px;
        border: 1px solid #80B5FF;
    }
    .address{
        color: #999;
        font-size: 13px;
    }
</style>
