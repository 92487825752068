<template>
    <div class="GoodsDetail">
        <van-nav-bar title="商品详情" :fixed="true"></van-nav-bar>
        <van-swipe :autoplay="3000" style="margin-top: 46px">
            <van-swipe-item v-for="(image, index) in gallery" :key="index">
                <img v-lazy="image" width="100%" />
            </van-swipe-item>
        </van-swipe>
        <div class="cell" style="font-size: 12px">
            <van-row style="padding-top: 15px" >
                <van-col span="22"  offset="2"><span style="font-size: 20px;color: #EC605B;margin-right: 10px" >￥{{list.presentPrice}}</span><span style="color: #999;text-decoration:line-through">￥{{list.originalPrice}}</span></van-col>
            </van-row>
            <van-row style="padding-top: 5px" >
                <van-col span="22"  offset="2"><van-tag  size="medium" color="#F8E9E7" text-color="#EC605B" v-for="(item,index) in list.tags" :key="index">
                    {{item}}</van-tag></van-col>
            </van-row>
            <van-row style="padding-top: 10px" >
                <van-col span="22"  offset="2" style="color: #19110B;font-size: 16px;font-weight: 600">{{list.goodsName}}</van-col>
            </van-row>
        </div>
        <div class="cell">
            <van-row style="padding-top: 20px" >
                <van-col span="22"  offset="2"><span style="color: #999999;margin-right: 10px" >物流信息</span><span style="color: #333333;">{{list.logistics}}</span></van-col>
            </van-row>
        </div>
        <div style="width: 100%;height: 50px;line-height: 50px;color: #999999;text-align: center">宝贝详情</div>
        <div v-html="list.detail" style="width: 95%;margin: auto"></div>
        <van-goods-action style="padding-left: 5px">
            <van-goods-action-icon icon="chat-o" text="联系客服"  @click="onClickButton" />
            <van-goods-action-icon icon="star-o" text="收藏"   @click="onClickButton" />
            <van-goods-action-button color="#8ed6fe" type="warning" text="加入购物车"  @click="onClickButton" />
            <van-goods-action-button color="#43abfc" type="danger" text="立即购买"   @click="onClickButton"/>
        </van-goods-action>
    </div>
</template>

<script>
  import {goods} from "../api";

  export default {
    name: "GoodsDetail",
    data() {
      return {
        goodsId: '1403722340285660416',
        gallery:[],
        list:{}
      }
    },
    methods: {
      getDetail() {
        goods(this.goodsId).then(resp => {
          this.list= resp.data
          this.gallery = resp.data.gallery
        })
      },
      onClickButton(){
        this.$router.push({name:'download'})
      }
    },
    mounted() {
      this.goodsId = this.$route.query.goodsId
      this.getDetail()
    }
  }
</script>

<style scoped>
.cell{
    width: 100%;
    margin-top: 10px;
    border-radius: 14px;
    /*height: 200px;*/
    background-color: #ffffff;
    padding-bottom: 20px;
}
</style>
