<template>
    <div class="box">
        <van-row>
            <van-col span="24">
                <video :src="list.videoUrl" poster="../assets/android.png" controls="controls" height="300px"
                       width="100%">
                </video>
            </van-col>
        </van-row>
        <van-row style="margin-top: 15px">
            <van-col span="14" :offset="1" style="font-size: 24px;color: #333333;font-weight: bold">
                {{list.schoolName}}
            </van-col>
            <van-col span="8" style="text-align: right">{{list.schoolBrief}}</van-col>
        </van-row>
        <van-row style="margin-top: 15px">
            <van-col span="23" :offset="1">
                办学性质：{{list.nature}}
            </van-col>
        </van-row>
        <van-row style="margin-top: 15px">
            <van-col span="22" :offset="1">
                <hr/>
            </van-col>
        </van-row>
        <van-row style="margin-top: 15px">
            <van-col span="22" :offset="1">
                {{list.addressDetail}}
            </van-col>
        </van-row>
        <van-row style="margin-top: 15px;color: #999999">
            <van-col span="22" :offset="1">
                {{list.route}}
            </van-col>
        </van-row>
        <van-row style="margin-top: 15px">
            <van-col span="24">
                <hr style="height: 9px;background: #ECECEC;">
            </van-col>
        </van-row>
        <van-row style="margin-top: 15px;color: #999999">
            <van-col span="22" :offset="1">
                <div style="display: flex">
                    <hr style="height: 25px;width: 7px;border-radius: 3.5px;background-color: #4AADFF ">
                    <p style="color: #212121;height: 25px;line-height: 25px;margin-left: 10px;font-size: 20px">简介</p>
                </div>
            </van-col>
        </van-row>
        <van-row style="margin-top: 15px">
            <van-col span="22" :offset="1">
                <div v-html="list.richText"></div>
            </van-col>
        </van-row>
        <div style="position: fixed;bottom: 20px;right: 20px" @click="onClickButton"><img :src="imgUrl"></div>
    </div>
</template>

<script>
  import {planSchool} from "../api";

  export default {
    name: "PlanSchool",
    data() {
      return {
        planSchoolId: "",
        list: {},
        imgUrl: require('../assets/head.png')
      }
    },
    methods: {
      getDetail() {
        planSchool(this.planSchoolId).then(resp => {
          this.list = resp.data
        })
      },
      onClickButton() {
        this.$router.push({name: 'download'})
      }
    },
    mounted() {
      // 1403762566949359681
      this.planSchoolId = this.$route.query.planSchoolId
      this.getDetail()
    }
  }
</script>

<style scoped>
    .box {
        background-color: #ffffff;
        height: 100vh;
        overflow: scroll;
        color: #333333
    }

    hr {
        height: 1px;
        background: #ECECEC;
    }
</style>
