<template>
    <div class="home">
        <div v-html="data"></div>
    </div>
</template>

<script>
    import {carousel} from "../api";

    export default {
        name: "carousel",
        data() {
            return {
                carouselId: '',
                data: ''
            }
        },
        methods: {
            getData() {
                carousel(this.carouselId).then(resp => {
                    this.data = resp.data
                })
            }
        },
        mounted() {
            this.carouselId = this.$route.query.carouselId
            console.log(this.carouselId)
            if (this.carouselId !== '' && this.carouselId !== undefined) {
                this.getData()
            }
        }
    }
</script>

<style scoped>

</style>
