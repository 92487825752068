import axios from "axios";
// import qs from "qs";
import {Notify} from 'vant';

let config = {
  baseURL: process.env.VUE_APP_baseURL,
  timeout: 10 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const service = axios.create(config);

service.interceptors.request.use(
  config => {
    // config.headers['token'] = sessionStorage.getItem("token")
    return config
  },
  error => {
    console.log(error)
    // Do something with request error
    Promise.reject(error)
  }
)

// Add a response interceptor
// response 拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    console.log(res.code)
    if (res.code === 500) {
      Notify({type: 'danger', message: res.msg})
      return Promise.reject(res.msg)
    }
    if (res.code !== 0) {
      Notify({type: 'danger', message: '网络异常'})
      return Promise.reject('网络异常')
    }
    return response.data
  },
  error => {
    return Promise.reject(error)
  }
)

export default service;
