<template>
    <div class="download">
        <p class="title">学子笈</p>
        <p class="subtitle">学校更省心·家长更放心·学生更悦心</p>
        <div class="imgBox">
            <img src="../assets/home.png">
            <!--            <img src="../assets/login.jpg">-->
        </div>
        <div class="btnBox">
        </div>
        <div class="btn" @click="downloadAndroid"><img src="../assets/android.png"
                                                       style="vertical-align: middle;margin-right: 5px">Android下载
        </div>
        <div class="btn"     @click="downloadIos"  style="margin-top: 20px;bottom: 35px;color: #1296db"><img src="../assets/ios.png"
                                                                                                             style="vertical-align: middle;margin-right: 5px;">IOS下载
        </div>

    </div>
</template>

<script>
  export default {
    name: "Download",
    data() {
      return {
        androidUrl: process.env.VUE_APP_AndroidURL,
        iosUrl:process.env.VUE_APP_iosURL,
      }
    },
    methods: {
      downloadAndroid() {
        window.location.href=this.androidUrl
      },
      downloadIos(){
        window.location.href=this.iosUrl
      }
    }
  }
</script>

<style scoped>
    .download {
        width: 100%;
        height: 100vh;
        background-color: #0295e3;
        color: #ffffff;
        /*background: linear-gradient(to bottom, #3cb9fb, #0295e3);*/
        /*background: linear-gradient(to bottom, #004062 , #73c4ef);*/
        text-align: center;
        overflow: hidden;
    }

    .title {
        font-size: 48px;
        margin-top: 8%;
        font-family: cursive;
        font-weight: 600;
    }

    .subtitle {
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 33px;
        font-size: 33px;
        font-family: monospace;
        line-height: 41px;
    }

    .imgBox img {
        width: 50%;
        margin-top: 20px;
    }

    .btnBox {
        position: fixed;
        bottom: -20px;
        height: 200px;
        width: 100%;
        z-index: 2;
        transform: skew(0deg, -6deg);
        background: linear-gradient(to bottom, #3cb9fb, #0295e3);
    }

    .btn {
        height: 50px;
        width: 80%;
        border-radius: 10px;
        background-color: white;
        z-index: 3;
        position: fixed;
        bottom: 100px;
        margin-left: 10%;
        line-height: 50px;
        color: #2fc2b0;
    }

</style>
