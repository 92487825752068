<template>
    <div>
        <img :src="imgUrl" width="100%">
        <div class="join">
            <van-button color="#ffc000" style="color: #211f3d" @click="showPopup">立即加盟</van-button>
        </div>
        <van-popup v-model="show" closeable
                   close-icon="close"
                   position="bottom" round :style="{ height: '40%' }">
            <div style="margin-top: 55px">
                <van-form @submit="onSubmit">
                    <van-field
                            v-model="username"
                            name="用户名"
                            placeholder="用户名"
                    ></van-field>
                    <van-field
                            v-model="mobile"
                            type="tel"
                            name="手机号"
                            placeholder="手机号"
                    ></van-field>
                    <div style="margin: 16px;">
                        <van-button block color="#ffc000"
                                    style="color: #211f3d;border-radius: 5px;font-size: 14px;margin-top: 25px"
                                    native-type="submit">申请加盟
                        </van-button>
                    </div>
                </van-form>
            </div>
        </van-popup>
    </div>
</template>

<script>
    import {join} from '../api/index'
  export default {
    name: "jion",
    data() {
      return {
        imgUrl: require('../assets/join.png'),
        show: false,
        username: '',
        mobile: '',
      }
    },
    methods: {
      showPopup() {
        this.show = true;
      },
      onSubmit: function (values) {
        console.log(values)
        var text = /^1[0-9][0-9]\d{8}$/
        if (this.username === "") {
          this.$notify({type: 'danger', message: '请填写姓名'});
        } else if (this.mobile === "") {
          this.$notify({type: 'danger', message: '请填写手机号'});
        } else if (this.mobile != '') {
          if (text.test(this.mobile)) {
            join(this.username, this.mobile).then(resp => {
              console.log(resp)
              this.$notify({type: 'success', message: '报名成功'});
            })
          } else {
            this.$notify({type: 'danger', message: '手机号格式不正确'});

          }
        }
      },
    }
  }
</script>

<style scoped>
    .join {
        position: fixed;
        bottom: 50px;
        right: 50px;
        color: #211f3d;
        font-size: 14px;
    }

    /deep/ .van-button--normal {
        padding: 0 15px;
        font-size: 14px;
        color: #211f3d !important;
        font-weight: bold;

    }

    /deep/ .van-cell {
        padding: 0px;
    }

    /deep/ .van-field__control {
        border: 1px solid;
        color: #9fa9b3;
        /*width: 80%;*/
        height: 40px;
        line-height: 40px;
        margin: 16px;
        margin-bottom: 5px;
        border-radius: 5px;
        padding-left: 10px;
        outline: none;
        background: #fff;
    }

    /deep/ .van-cell::after {
        border-bottom: 0px solid #ebedf0;

    }

</style>
