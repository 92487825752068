import { render, staticRenderFns } from "./StudentList.vue?vue&type=template&id=2203bc70&scoped=true&"
import script from "./StudentList.vue?vue&type=script&lang=js&"
export * from "./StudentList.vue?vue&type=script&lang=js&"
import style0 from "./StudentList.vue?vue&type=style&index=0&id=2203bc70&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2203bc70",
  null
  
)

export default component.exports