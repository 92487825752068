<template>
    <div>
        <!--        <van-nav-bar title=""></van-nav-bar>-->
        <div class="home">
            <div v-html="data" class="text"></div>
        </div>
        <div class="btn" @click="onClickButton">我要报名</div>
    </div>
</template>

<script>
  import {activity} from "../api";

  export default {
    name: "Home",
    data() {
      return {
        activityId: 1415769800860411584,
        data: ''
      }
    },
    methods: {
      getData() {
        activity(this.activityId).then(resp => {
          console.log()
          this.data = resp.data
        })
      },
      onClickButton() {
        this.$router.push({name: 'download'})
      }
    },
    mounted() {
      this.activityId = this.$route.query.activityId
      this.getData()
    }
  }
</script>

<style scoped>
    .home {
        width: 90%;
        padding-top: 10px;
        margin: auto;
        height: 95vh;
        overflow: scroll;
    }

    .text img {

    }

    .btn {
        width: 100%;
        position: fixed;
        bottom: 0;
        height: 50px;
        text-align: center;
        color: #ffffff;
        background-color: #0895FF;
        line-height: 50px;
        font-weight: bold;
    }
</style>
