<template>
    <div id="app">
        <!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
        <keep-alive exclude="OrderSuccess">
            <router-view/>
        </keep-alive>
    </div>
</template>

<script>

    export default {
        name: 'App',
        created() {
            this.$router.beforeEach((to, from, next) => {
                document.title = to.meta.title || '学子笈' // 获取路由元信息中的标题或者默认空值作为网页标题
                next()
            })
        }
    }
</script>

<style>
    #app {
        /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
        font-family: 'Helvetica Neue', Helvetica, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 17px;
        line-height: 21px;
        /*text-align: center;*/
        color: #000000;
        /*margin-top: 60px;*/
        background: #EFEFF4;
        height: 100vh;
        /*padding-left: 30px;*/
        /*padding-top: 30px;*/
        /*padding-right: 30px;*/
        font-weight: 400;
    }
</style>
