<template>
    <div class="home">
        <!--        <van-nav-bar title=""></van-nav-bar>-->
        <div v-html="data" class="text"></div>
    </div>
</template>

<script>
  import {doc} from "../api";

  export default {
    name: "Home",
    data() {
      return {
        docId: '',
        data: ''
      }
    },
    methods: {
      getData() {
        doc(this.docId).then(resp => {
          this.data = resp.data
        })
      }
    },
    mounted() {
      this.docId = this.$route.query.docId
      this.getData()
    }
  }
</script>

<style scoped>
    .home {
        width: 90%;
        padding-top: 10px;
        margin: auto;
        /*padding-bottom: 20px;*/
        height: 95vh;
        overflow: scroll;
        overflow-x: hidden;
    }

    .text img {
        width: 100%;
    }
</style>
