<template>
    <div class="box">
        <van-row>
            <van-col span="24">
                <video :src="list.mediaUrl" :poster="list.thumbnailUrl" controls="controls" height="300px"
                       width="100%">
                </video>
            </van-col>
        </van-row>
        <van-row style="margin-top: 10px;background-color: #ececec;height: 50px;line-height: 50px;font-size: 14px">
            <van-col span="14" :offset="1" style="font-size: 14px;color: #333333;">
                {{list.courseName}}
            </van-col>
            <van-col span="8" style="text-align: right">{{list.countStudy}}人已学习</van-col>
        </van-row>
        <van-row style="margin-top: 15px">
            <van-col span="23" :offset="1" style="font-weight: bold">
                {{list.trainingSchoolName}}
            </van-col>
        </van-row>
        <van-row style="margin-top: 15px">
            <van-col span="23" :offset="1" style="">
                {{list.trainingSchoolBrief}}
            </van-col>
        </van-row>
        <van-row style="margin-top: 15px">
            <van-col span="22" :offset="1">
                <hr/>
            </van-col>
        </van-row>
        <van-row style="margin-top: 15px">
            <van-col span="23" :offset="1" style="">
                {{list.mainAddress}}({{list.subAddress}})
            </van-col>
        </van-row>
        <van-row style="margin-top: 15px">
            <van-col span="23" :offset="1" style="">
                {{list.route}}
            </van-col>
        </van-row>
        <van-row style="margin-top: 15px">
            <van-col span="24">
                <hr style="height: 9px;background: #ECECEC;">
            </van-col>
        </van-row>
        <van-row style="margin-top: 15px;color: #999999">
            <van-col span="22" :offset="1">
                <div style="display: flex">
                    <hr style="height: 22px;width: 7px;border-radius: 3.5px;background-color: #4AADFF ">
                    <p style="color: #212121;height: 25px;line-height: 25px;margin-left: 10px;font-size: 20px">相关课程</p>
                </div>
            </van-col>
        </van-row>
        <div style="margin-top: 15px;margin-bottom: 60px;overflow: hidden">
            <div class="courses" v-for="(item,index)  in list.relatedCourse" :key="index"  @click="onClickButton">
                <div class="imgBox"><img :src="item.thumbnail" @error="imgError" class="img">
                    <div style="position: absolute;z-index: 100;left: 40%;top: 40%"><img src="../assets/arrow.png" width="30px" height="30px"></div>
                </div>
                <van-row style="margin-top: 5px">
                    <van-col span="20" :offset="3" style="font-size: 14px">
                        {{item.courseName}}
                    </van-col>
                </van-row>
                <van-row style="margin-top: 5px;font-size: 14px">
                    <van-col span="11" :offset="2" style="font-size: 14px">
                        《{{item.album}}》
                    </van-col>
                    <van-col span="11" style="text-align: right">{{item.countStudy}}人已学习</van-col>
                </van-row>
            </div>
        </div>
        <div style="position: fixed;z-index:200;bottom: 0px;width: 100%;height: 60px;background-color: white;text-align: center"
             @click="onClickButton">
            <van-button color="#FA6E46" @click="onClickButton" style="width: 80%;margin-top: 10px;border-radius: 40px">
                报名
            </van-button>

        </div>

    </div>
</template>


<script>
  import {experienceCourse} from "../api";
  export default {
    name: "ExperienceCourse",
    data() {
      return {
        experienceCourseId: "1404495408629147713",
        list: {},
        imgUrl: require('../assets/head.png')
      }
    },
    methods: {
      getDetail() {
        experienceCourse(this.experienceCourseId).then(resp => {
          this.list = resp.data
        })
      },
      onClickButton() {
        this.$router.push({name: 'download'})
      },
      imgError() {
        let img = event.srcElement
        img.src = require('../assets/head.png')
      }
    },
    mounted() {
      // 1403762566949359681
      this.experienceCourseId = this.$route.query.experienceCourseId
      this.getDetail()
    }
  }
</script>

<style scoped>
    .box {
        background-color: #ffffff;
        height: 100vh;
        overflow: scroll;
        color: #333333
    }

    hr {
        height: 1px;
        background: #ECECEC;
    }

    .courses {
        width: 50%;
        float: left;

    }

    .imgBox {
        position: relative;
    }

    .img {
        width: 90%;
        margin: 5%;
        height: 150px;
    }
</style>
