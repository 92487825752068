<template>
    <div class="home">
        <!--        <van-nav-bar title=""></van-nav-bar>-->
        <div class="text">
            <h3 style="text-align: center">{{data.title}}</h3>
            <div class="imgBox"><img :src="data.gallery" width="100%" height="200px"></div>
            <p style="padding: 10px;margin-bottom: 20px;font-size: 16px;text-indent: 2em;line-height: 1.5">{{data.content}}</p>
        </div>
    </div>
</template>

<script>
  import {growthInfo} from "../api";

  export default {
    name: "Growth",
    data() {
      return {
        id: '',
        data: ''
      }
    },
    methods: {
      getData() {
        growthInfo(this.id).then(resp => {
          this.data = resp.data
        })
      }
    },
    mounted() {
      this.id = this.$route.query.id
      this.getData()
    }
  }
</script>

<style scoped>
    .home {
        width: 100%;
        padding-top: 20px;
        margin: auto;
        height: 100vh;
        overflow: scroll;
        background-color: #ffffff;
    }

    .imgBox {
        width: 90%;
        margin: auto;
        margin-top: 20px;
    }
</style>
